import {EditBoardTemplate} from "../../components/templates/board/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditBoard = () => {
    return (
        <LayoutTemplate>
            <EditBoardTemplate />
        </LayoutTemplate>
    );
};
