import {atom, SetterOrUpdater} from 'recoil';
import {userTypes} from "../types/user";

export const userState = atom<userTypes>({
    key: 'user',
    default: {
        id: '',
        name: '',
        role: '',
    }
});

export const setUser = (hook: SetterOrUpdater<any>, {id, name, role}: Pick<userTypes, 'id' | 'name' | 'role'>) => {
    hook({
        id,
        name,
        role,
    });
};

export const deleteUser = (hook: SetterOrUpdater<any>) => {
    hook({
        id: '',
        name: '',
        role: '',
    });
};
