import {NoticeTemplate} from "../../components/templates/notice";
import LayoutTemplate from "../../components/templates/layout/left";

export const Notice = () => {
    return (
        <LayoutTemplate>
            <NoticeTemplate />
        </LayoutTemplate>
    );
};
