import {PlanTemplate} from "../../components/templates/plan";
import LayoutTemplate from "../../components/templates/layout/left";

export const Plan = () => {
    return (
        <LayoutTemplate>
            <PlanTemplate />
        </LayoutTemplate>
    );
};
