import React, {useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useFindIntroduces, useUpdateIntroduce} from "../../../api/introduce";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {Editor} from "../../atoms/editor";

export const IntroduceTemplate = () => {
    const {data: introduces} = useFindIntroduces();
    const {mutate, isLoading} = useUpdateIntroduce();
    const setAlert = useSetRecoilState(alertState);
    const [info, setInfo] = useState({
        seq: introduces?.data[0]?.seq,
        content: '',
        fileSeq:[''],
        showYn: 1,
    });

    const onSubmit = useCallback(() => {
        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        showAlert(setAlert, {title: '수정 하시겠습니까?', onSubmit: () =>
                mutate(info, {
                    onSuccess: () => {
                        closeAlert(setAlert);
                        window.location.reload();
                    }
                }),
        })
    }, [info]);


    useEffect(() => {
        if (introduces?.data[0]) {
            setInfo({
                seq: introduces?.data[0]?.seq,
                content: introduces?.data[0]?.content,
                showYn: introduces?.data[0]?.showYn,
                fileSeq: ['']
            });
        }
    }, [introduces]);

    return (
        <LayoutRightTemplate title={'소개 관리'} clickTitle={'수정'} onClick={onSubmit} isLoading={isLoading}>
            <Editor value={info.content} name={'content'} setter={setInfo}/>
        </LayoutRightTemplate>
    );
};
