import {EditBannerTemplate} from "../../components/templates/banner/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditBanner = () => {
    return (
        <LayoutTemplate>
            <EditBannerTemplate />
        </LayoutTemplate>
    );
};
