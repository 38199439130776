export const sizes = {
    xxs: 'xxs',
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
} as const;
export type sizes = typeof sizes[keyof typeof sizes];

export const shapes = {
    normal: 'normal',
    rectangle: 'rectangle',
    circle: 'circle'
} as const;
export type shapes = typeof shapes[keyof typeof shapes];
