import {EditUserTemplate} from "../../components/templates/user/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditUser = () => {
    return (
        <LayoutTemplate>
            <EditUserTemplate />
        </LayoutTemplate>
    );
};
