import styled from "styled-components";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import logo from '../../../assets/images/logo.png';
import {useLogin} from "../../../api/user";
import {ChangeEvent, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import background from '../../../assets/images/background.png';
import {sizes} from "../../../types";
import {colors} from "../../../styles/colors";

export const LoginTemplate = () => {
    const {mutate, isLoading} = useLogin();
    const navigate = useNavigate();
    const [info, setInfo] = useState({id: '', password: ''});

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.id && !info.password) {
            alert('아이디와 패스워드를 입력해주세요.');
            return;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/user');
            },
        });
    }, [info]);

    return (
        <Wrap>
            <LoginWrap>
                <LoginLeftWrap>
                    <Logo src={logo} alt={'logo'}/>

                    <LoginLeftInputWrap>
                        <span>Email</span>
                        <Input placeholder={'아이디 입력'} width={'100%'} value={info.id} name={'id'} onChange={onChangeInfo}/>
                    </LoginLeftInputWrap>

                    <LoginLeftInputWrap>
                        <span>Password</span>
                        <Input type={'password'} placeholder={'비밀번호 입력'} value={info.password} width={'100%'} name={'password'}
                               onChange={onChangeInfo}/>
                    </LoginLeftInputWrap>
                    <Button onClick={onSubmit} width={'100%'} size={sizes.md} $isLoading={isLoading}>Sign in</Button>
                </LoginLeftWrap>

                <LoginRightWrap>
                    <Background src={background}/>
                </LoginRightWrap>
            </LoginWrap>
        </Wrap>
    )
};

const Wrap = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background};
`

const LoginWrap = styled.div`
  display: flex;
  width: 90vw;
  max-width: 1000px;
  height: 70vh;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 12px 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LoginLeftWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 40%;
  background-color: #fff;
  padding: 0 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-radius: 12px 0 0 12px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0 0 12px 12px;
    order: 1;
  }
`

const LoginLeftInputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
`

const LoginRightWrap = styled.div`
  flex: 1;

  @media screen and (max-width: 768px) {
    flex: none;
    order: 0;
    height: 200px;
  }
 `

const Logo = styled.img`
  width: 150px;
`;

const Background = styled.div<{ src: string }>`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: ${({src}) => `url(${src})`};
  background-position: left top;
  background-size: cover;

  @media screen and (max-width: 768px) {
    border-radius: 12px 12px 0 0;
  }
`
