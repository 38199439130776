import styled from "styled-components";

type props = {
    width?: string;
    height?: string;
    background?: string;
    margin?: string;
    cursor?: string;
    onClick?(): void;
}

type styledTypes = Pick<props, 'width' | 'height' | 'background' | 'margin' | 'cursor'>;

const Icon = ({width, height, background, margin, cursor, onClick}:props) => {
    return (
        <IconWrap background={background} width={width} height={height} margin={margin} cursor={cursor} onClick={onClick}/>
    )
}

const IconWrap = styled.i<styledTypes>`
  display: block;
  width: ${({width}) => width};
  height: ${({height}) => height};
  background: ${({background}) => background};
  margin: ${({margin}) => margin};
  cursor: ${({cursor}) => cursor};
`;

export default Icon;
