import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {userTypes} from "../types/user";

export const useLogout = () => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/user/logout`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        }
    );
}

export const useLogin = () => {
    const api = useCallback((data: Pick<userTypes, 'id' | 'password'>) => {
        return axios.post(`/admin/api/v1/user/login`, data);
    }, []);

    return useMutation(
        async (data: Pick<userTypes, 'id' | 'password'>) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useLoginCheck = () => {
    const api = useCallback(() => {
        return axios.post('/admin/api/v1/user/login/check');
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useUpdateUser = () => {
    const api = useCallback((data: FormData) => {
        return axios.put('/admin/api/v1/user', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertUser = () => {
    const api = useCallback((data: FormData) => {
        return axios.post('/admin/api/v1/user', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteUser = () => {
    const api = useCallback(({seq}: Pick<userTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/user/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<userTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindUser = ({seq}: userTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/user/${seq}`);
    }, []);

    return useQuery(
        ['userDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindUsers = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/user/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['user', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
