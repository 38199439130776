import {QnaTemplate} from "../../components/templates/qna";
import LayoutTemplate from "../../components/templates/layout/left";

export const Qna = () => {
    return (
        <LayoutTemplate>
            <QnaTemplate />
        </LayoutTemplate>
    );
};
