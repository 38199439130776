import {useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {noticeTypes} from "../../../types/notice";
import {useDeleteNotice, useFindNotices} from "../../../api/notice";
import Action from "../../atoms/table/action";
import {useSetRecoilState} from "recoil";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useQueryClient} from "react-query";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import Pagination from "../../atoms/table/pagination";
import {alertLevels} from "../../../types/alert";

export const NoticeTemplate = () => {
    const {data: notices, isLoading} = useFindNotices();
    const navigate = useNavigate();
    const {page} = useGetQueryString();
    const {mutate} = useDeleteNotice();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/notice/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/notice/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', level: alertLevels.warning, onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['notice']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '5%'},
            {header: '제목', accessor: 'title', width: '60%'},
            {header: '조회 수', accessor: 'viewCount', width: '10%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => notices?.data && notices?.data.length > 0 ? notices?.data.map((x: noticeTypes) => {
        return {
            ...x,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [notices]);

    return (
        <LayoutRightTemplate title={'공지사항 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq)=>goEdit(seq)} isLoading={isLoading}/>
            <Pagination count={notices ? notices?.count[0]?.count || 1 : ''} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
