import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {planTypes} from "../types/plan";

export const useUpdatePlan = () => {
    const api = useCallback((data: planTypes) => {
        return axios.put('/admin/api/v1/plan', data);
    }, []);

    return useMutation(
        async (data: planTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertPlan = () => {
    const api = useCallback((data: planTypes) => {
        return axios.post('/admin/api/v1/plan', data);
    }, []);

    return useMutation(
        async (data: planTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeletePlan = () => {
    const api = useCallback(({seq}: Pick<planTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/plan/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<planTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindPlan = ({seq}: planTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/plan/${seq}`);
    }, []);

    return useQuery(
        ['planDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindPlans = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/plan/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['plan', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
