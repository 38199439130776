import {PrivacyTemplate} from "../../components/templates/privacy";
import LayoutTemplate from "../../components/templates/layout/left";

export const Privacy = () => {
    return (
        <LayoutTemplate>
            <PrivacyTemplate />
        </LayoutTemplate>
    );
};
