import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertBanner} from "../../../api/banner";
import {useNavigate} from "react-router-dom";
import {roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import {FormMultiWrap, FormWrap} from "../../../styles/component";

export const AddBannerTemplate = () => {
    const {mutate, isLoading} = useInsertBanner();
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        bannerFile: '',
        showYn: 1
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.bannerFile || !info.showYn) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.bannerFile) formData.append('bannerFile', info.bannerFile);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/banner');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'배너 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormMultiWrap>
                <FormWrap>
                    <span> 이미지 업로드 </span>
                    <InputPreviewFile id={'file1'} name={'bannerFile'} accept={'image/*'} initUrl={info.bannerFile} setter={setInfo}/>

                    <span> 공개 </span>
                    <Select items={showYnItems} essential={true} value={info.showYn} name={'showYn'} onChange={onChangeInfo}/>
                </FormWrap>
            </FormMultiWrap>
        </LayoutRightTemplate>
    )
}
