import styled from "styled-components";

type props = {
    seq: string,
    goEdit(seq: string): void,
    onDelete(seq: string): void,
    onCreate?(seq: string): void,
    onInvoiceCreateModal?(seq: string): void,
}

const TableAction = ({seq, goEdit, onDelete, onInvoiceCreateModal}: props) => {
    return (
        <ActionWrap>
            {
                onInvoiceCreateModal &&
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="gray" strokeWidth="2" onClick={() => onInvoiceCreateModal(seq)}
                     strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye">
                    <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/>
                    <circle cx="12" cy="12" r="3"/>
                </svg>
            }
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" onClick={() => goEdit(seq)}
                 stroke="blueviolet" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-edit-2">
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="1 8" viewBox="0 0 24 24" onClick={() => onDelete(seq)}
                 stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"/>
                <line x1="6" y1="6" x2="18" y2="18"/>
            </svg>
        </ActionWrap>
    )
}

const ActionWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  & svg {
    cursor: pointer;
  }
`

export default TableAction
