import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {introduceTypes} from "../types/introduce";

export const useUpdateIntroduce = () => {
    const api = useCallback((data: introduceTypes) => {
        return axios.put('/admin/api/v1/introduce', data);
    }, []);

    return useMutation(
        async (data: introduceTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertIntroduce = () => {
    const api = useCallback((data: introduceTypes) => {
        return axios.post('/admin/api/v1/introduce', data);
    }, []);

    return useMutation(
        async (data: introduceTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteIntroduce = () => {
    const api = useCallback(({seq}: Pick<introduceTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/introduce/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<introduceTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindIntroduce = ({seq}: introduceTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/introduce/${seq}`);
    }, []);

    return useQuery(
        ['introduceDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindIntroduces = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/introduce/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['introduce', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
