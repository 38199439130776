import {useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {Link, useNavigate} from "react-router-dom";
import {popupTypes} from "../../../types/popup";
import {useDeletePopup, useFindPopups} from "../../../api/popup";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import {alertLevels} from "../../../types/alert";

export const PopupTemplate = () => {
    const {data: popups, isLoading} = useFindPopups();
    const navigate = useNavigate();
    const {mutate} = useDeletePopup();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/popup/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/popup/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', level: alertLevels.warning, onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['popup']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '5%'},
            {header: '이미지', accessor: 'popupFile', width: '70%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => popups?.data && popups?.data.length > 0 ? popups?.data.map((x: popupTypes) => {
        return {
            ...x,
            popupFile: <img src={x.popupFile} alt={'popup'} width={'200'}></img>,
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [popups]);

    return (
        <LayoutRightTemplate title={'팝업 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goEdit(seq)} isLoading={isLoading}/>
            <Pagination count={popups ? popups?.count || 1 : ''} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
