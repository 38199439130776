import {IntroduceTemplate} from "../../components/templates/introduce";
import LayoutTemplate from "../../components/templates/layout/left";

export const Introduce = () => {
    return (
        <LayoutTemplate>
            <IntroduceTemplate />
        </LayoutTemplate>
    );
};
