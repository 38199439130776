import Input from "../../atoms/input";
import styled from "styled-components";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindQna, useUpdateQna} from "../../../api/qna";
import {roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {Editor} from "../../atoms/editor";
import {FlexWrap, FormWrap} from "../../../styles/component";

export const EditQnaTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindQna({seq});
    const {mutate, isLoading} = useUpdateQna();
    const [info, setInfo] = useState({
        seq,
        title: '',
        content: '',
        answer: '',
        showYn: 0,
        fileSeq: [''],
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.title || !info.content) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/qna');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: data?.seq,
                title: data?.title,
                content: data?.content,
                answer: data?.answer,
                showYn: data?.showYn,
                fileSeq: [''],
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'1:1 문의 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FlexWrap $gap={50} $height={100}>
                <FormWrap>
                    <span> 제목 </span>
                    <Input type={"text"} value={info.title} name={'title'} onChange={onChangeInfo}/>

                    <span> 공개 </span>
                    <Select items={showYnItems} essential={true} value={info.showYn} name={'showYn'} onChange={onChangeInfo}/>
                </FormWrap>

                <FlexWrap $columns={true} $gap={10} $width={100} >
                    <span> 내용 </span>
                    <Editor value={info.content} name={'content'} setter={setInfo} isUploader={false}/>
                </FlexWrap>

                <FlexWrap $columns={true} $gap={10} $width={100} >
                    <span> 답변 </span>
                    <Editor value={info.answer} name={'answer'} setter={setInfo} isUploader={false}/>
                </FlexWrap>
            </FlexWrap>
        </LayoutRightTemplate>
    )
}
