import {BoardTemplate} from "../../components/templates/board";
import LayoutTemplate from "../../components/templates/layout/left";

export const Board = () => {
    return (
        <LayoutTemplate>
            <BoardTemplate />
        </LayoutTemplate>
    );
};
