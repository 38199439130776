import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {privacyTypes} from "../types/privacy";

export const useUpdatePrivacy = () => {
    const api = useCallback((data: privacyTypes) => {
        return axios.put('/admin/api/v1/privacy', data);
    }, []);

    return useMutation(
        async (data: privacyTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeletePrivacy = () => {
    const api = useCallback(({seq}: Pick<privacyTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/privacy/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<privacyTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useInsertPrivacy = () => {
    const api = useCallback((data: privacyTypes) => {
        return axios.post('/admin/api/v1/privacy', data);
    }, []);

    return useMutation(
        async (data: privacyTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useFindPrivacy = ({seq}: privacyTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/privacy/${seq}`);
    }, []);

    return useQuery(
        ['privacyDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindPrivacys = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/privacy/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['privacy', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
