import styled from "styled-components";
import {ReactNode} from "react";
import {sizes} from "../../../types";
import {colors} from "../../../styles/colors";

type props = {
  children?: ReactNode;
  size?: sizes;
  theme?: tagThemes;
  onClick?(): void;
}

export const tagThemes = {
  normal: 'normal',
  red: 'red',
  navy: 'navy',
  green: 'green',
  orange: 'orange',
  violet: 'violet',
} as const;
export type tagThemes = typeof tagThemes[keyof typeof tagThemes];

const Tag = ({children, size, theme, onClick}:props) => {
    return (
        <SizeTagWrap size={size} theme={theme} onClick={onClick}>
            {children}
        </SizeTagWrap>
    )
}

const TagWrap = styled.span<props>`
  display: flex;
  margin: 0 auto;
  padding: 4px;
  max-width: 60px;
  border-radius: 32px;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const ThemeTagWrap = styled(TagWrap)`
  ${({theme}) => {
    switch (theme) {
      case tagThemes.red:
        return `
           background-color: ${colors.red["060"]};
           color: #fff;
           cursor: pointer;
        `;
      case tagThemes.navy:
        return `
           background-color: ${colors.main};
           color: #fff;
        `;
      case tagThemes.green:
        return `
           background-color: ${colors.green["060"]};
           color: #fff;
        `;
      case tagThemes.orange:
        return `
           background-color: ${colors.orange["060"]};
           color: #fff;
        `;
      case tagThemes.violet:
        return `
           background-color: #9400d3;
           color: #fff;
        `;
      default:
        return;
    }
  }};
`;

const SizeTagWrap = styled(ThemeTagWrap)`
  ${({size}) => {
  switch (size) {
    case sizes.sm:
      return `width: 45px;`;
    case sizes.md:
      return `width: 60px;`;
    case sizes.lg:
      return `width: 75px;`;
    default:
      return ``;
  }
}};
`

export default Tag;
