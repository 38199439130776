import {EditPopupTemplate} from "../../components/templates/popup/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditPopup = () => {
    return (
        <LayoutTemplate>
            <EditPopupTemplate />
        </LayoutTemplate>
    );
};
