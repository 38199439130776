import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {invoiceTypes} from "../types/invoice";
import {privacyTypes} from "../types/privacy";

export const useUpdateInvoice = () => {
    const api = useCallback((data: FormData) => {
        return axios.put('/admin/api/v1/invoice', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}
export const useUpdateInvoiceStatusFinish = () => {
    const api = useCallback((data: invoiceTypes) => {
        return axios.put(`/admin/api/v1/invoice/status/finish`, data);
    }, []);

    return useMutation(
        async (data: invoiceTypes) => {
            const response = await api(data);
            return response.data.data;
        },
    );
}

export const useUpdateInvoiceStatus = () => {
    const api = useCallback((data: FormData) => {
        return axios.put(`/admin/api/v1/invoice/status`, data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data.data;
        },
    );
}

export const useInsertInvoice = () => {
    const api = useCallback((data: FormData) => {
        return axios.post('/admin/api/v1/invoice', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteInvoice = () => {
    const api = useCallback(({seq}: Pick<invoiceTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/invoice/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<invoiceTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindInvoice = ({seq}: invoiceTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/invoice/${seq}`);
    }, []);

    return useQuery(
        ['invoiceDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindInvoices = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/invoice/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['invoice', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
