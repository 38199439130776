import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {useEffect} from "react";
import {Login} from "../login";
import {useLoginCheck} from "../../api/user";
import {AddUser} from "../user/add";
import {User} from "../user";
import {EditUser} from "../user/edit";
import {Privacy} from "../privacy";
import {useSetRecoilState} from "recoil";
import {setUser, userState} from "../../recoil/user";
import {Board} from "../board";
import {AddBoard} from "../board/add";
import {EditBoard} from "../board/edit";
import {Notice} from "../notice";
import {AddNotice} from "../notice/add";
import {EditNotice} from "../notice/edit";
import {Qna} from "../qna";
import {AddQna} from "../qna/add";
import {EditQna} from "../qna/edit";
import {Invoice} from "../invoice";
import {AddInvoice} from "../invoice/add";
import {EditInvoice} from "../invoice/edit";
import {Introduce} from "../introduce";
import {Plan} from "../plan";
import {EditBanner} from "../banner/edit";
import {Banner} from "../banner";
import {AddBanner} from "../banner/add";
import {Popup} from "../popup";
import {AddPopup} from "../popup/add";
import {EditPopup} from "../popup/edit";

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {mutate} = useLoginCheck();
    const userStateHook = useSetRecoilState(userState);

    useEffect(() => {
        if (location.pathname === '/') return navigate('/user');
        if (location.pathname === '/login') return;

        mutate(undefined, {
            onSuccess: async ({data}) => {
                setUser(userStateHook, {id: data.id, name: data.name, role: data.role});
            },
            onError: async () => {
                if (location.pathname !== '/login') navigate('/login');
            }
        });
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/user" element={<User/>}/>
            <Route path="/user/add" element={<AddUser/>}/>
            <Route path="/user/edit/:seq" element={<EditUser/>}/>

            <Route path="/privacy" element={<Privacy/>}/>

            <Route path="/board" element={<Board/>}/>
            <Route path="/board/add" element={<AddBoard/>}/>
            <Route path="/board/edit/:seq" element={<EditBoard/>}/>

            <Route path="/notice" element={<Notice/>}/>
            <Route path="/notice/add" element={<AddNotice/>}/>
            <Route path="/notice/edit/:seq" element={<EditNotice/>}/>

            <Route path="/qna" element={<Qna/>}/>
            <Route path="/qna/add" element={<AddQna/>}/>
            <Route path="/qna/edit/:seq" element={<EditQna/>}/>

            <Route path="/invoice" element={<Invoice/>}/>
            <Route path="/invoice/add" element={<AddInvoice/>}/>
            <Route path="/invoice/edit/:seq" element={<EditInvoice/>}/>

            <Route path="/introduce" element={<Introduce/>}/>
            <Route path="/plan" element={<Plan/>}/>

            <Route path="/banner" element={<Banner/>}/>
            <Route path="/banner/add" element={<AddBanner/>}/>
            <Route path="/banner/edit/:seq" element={<EditBanner/>}/>

            <Route path="/popup" element={<Popup/>}/>
            <Route path="/popup/add" element={<AddPopup/>}/>
            <Route path="/popup/edit/:seq" element={<EditPopup/>}/>
        </Routes>
    );
}
