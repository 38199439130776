import Input from "../../atoms/input";
import styled from "styled-components";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindInvoice, useUpdateInvoice} from "../../../api/invoice";
import {roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {ResponsiveWrap} from "../../molecules/responsiveWrap";
import {FlexWrap, FormMultiWrap} from "../../../styles/component";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import Button from "../../atoms/button";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";

export const EditInvoiceTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindInvoice({seq});
    const {mutate, isLoading} = useUpdateInvoice();
    const [info, setInfo] = useState({
        seq,
        bizEngName: '',
        bizKrName: '',
        bizEngAddress: '',
        bizEngCeo: '',
        bizKrCeo: '',
        bizNumber: '',
        bizTelephone: '',
        filterItems:[],
        items: [{name: '', amount: '', price: ''}],
        chinaAccountNumber: '',
        chinaAccountBank: '',
        invoiceFile: '',
        junmoonFile: '',
        chinaAccountOwner: '',
        chinaAccountFile: '',
        jeungbingFile: '',
        image:'',
        showYn: 0,
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);



    const onSubmit = useCallback(() => {
        if (!info.bizEngName || !info.bizEngAddress) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        if (info.items.some(item => item.name !== '' || item.amount !== '' || item.price !== '')) {
            // 배열 내에 하나 이상의 값이 있는 경우
            setInfo((prev) => ({
                ...prev,
                items: data?.items
            }));
        } else {
            // 배열 내에 모든 값이 비어있는 경우
            info.items = info.filterItems
        }
        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.chinaAccountFile) formData.append('chinaAccountFile', info.chinaAccountFile);
        if (info.jeungbingFile) formData.append('jeungbingFile', info.jeungbingFile);
        if (info.junmoonFile) formData.append('junmoonFile', info.junmoonFile);
        if (info.invoiceFile) formData.append('invoiceFile', info.invoiceFile);


        mutate(formData, {
            onSuccess: async () => {
                navigate('/invoice');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: data?.seq,
                bizEngName: data?.bizEngName,
                bizKrName: data?.bizKrName,
                bizEngAddress: data?.bizEngAddress,
                bizEngCeo: data?.bizEngCeo,
                bizKrCeo: data?.bizKrCeo,
                bizNumber: data?.bizNumber,
                bizTelephone: data?.bizTelephone,
                filterItems: [],
                items: data?.items,
                invoiceFile: data?.invoiceFile,
                junmoonFile: data?.junmoonFile,
                chinaAccountNumber: data?.chinaAccountNumber,
                chinaAccountBank: data?.chinaAccountBank,
                chinaAccountOwner: data?.chinaAccountOwner,
                chinaAccountFile: data?.chinaAccountFile,
                jeungbingFile: data?.jeungbingFile,
                image: '',
                showYn: data?.showYn,
            });
        }
    }, [data]);
    return (
        <LayoutRightTemplate title={'송장 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormMultiWrap>
                <FormWrap>
                    <span> 사업자 상호명 (영문) </span>
                    <Input type={"text"} value={info.bizEngName} name={'bizEngName'} onChange={onChangeInfo}/>

                    <span> 사업자 상호명 (한글) </span>
                    <Input type={"text"} value={info.bizKrName} name={'bizKrName'} onChange={onChangeInfo}/>

                    <span> 사업자 번호 </span>
                    <Input type={"text"} value={info.bizNumber} name={'bizNumber'} onChange={onChangeInfo}/>

                    <span> 사업자 주소 </span>
                    <Input type={"text"} value={info.bizEngAddress} name={'bizEngAddress'} onChange={onChangeInfo}/>

                    <span> 대표자명 (영문) </span>
                    <Input type={"text"} value={info.bizEngCeo} name={'bizEngCeo'} onChange={onChangeInfo}/>

                    <span> 대표자명 (한글) </span>
                    <Input type={"text"} value={info.bizKrCeo} name={'bizKrCeo'} onChange={onChangeInfo}/>

                    <span> 연락처 </span>
                    <Input type={"text"} value={info.bizTelephone} name={'bizTelephone'} onChange={onChangeInfo}/>

                    <span> 공개 </span>
                    <Select items={showYnItems} essential={true} value={info.showYn} name={'showYn'} onChange={onChangeInfo}/>
                </FormWrap>

                <FormWrap>
                    <span> 중국 계좌번호 </span>
                    <Input type={"text"} value={info.chinaAccountNumber} name={'chinaAccountNumber'} onChange={onChangeInfo}/>

                    <span> 중국 은행명 </span>
                    <Input type={"text"} value={info.chinaAccountBank} name={'chinaAccountBank'} onChange={onChangeInfo}/>

                    <span> 중국계좌 수취인명 </span>
                    <Input type={"text"} value={info.chinaAccountOwner} name={'chinaAccountOwner'} onChange={onChangeInfo}/>

                    <span> 중국계좌 실사 첨부 </span>
                    <InputPreviewFile id={'file1'} name={'chinaAccountFile'} accept={'image/*'} initUrl={info.chinaAccountFile} setter={setInfo} />

                    <span> 증빙 자료 업로드 </span>
                    <InputPreviewFile id={'file2'} name={'jeungbingFile'} accept={'image/*'} initUrl={info.jeungbingFile} setter={setInfo} />

                    <span> 전문 서류 업로드 </span>
                    <InputPreviewFile id={'file3'} name={'junmoonFile'} accept={'image/*'} initUrl={info.junmoonFile} setter={setInfo} />

                    <span> 송장 파일 업로드 </span>
                    <InputPreviewFile id={'file4'} name={'invoiceFile'} accept={'.pdf'} initUrl={info.invoiceFile} setter={setInfo} />
                </FormWrap>

                <FormWrap>
                    <ResponsiveWrap title={'품목'} name={'items'} value={info.items} setter={setInfo} columnCount={2} maxLength={8}>
                        <span> 수입예정품목 </span>
                        <Input type={"text"} name={'name'}/>
                        <span> 수량 </span>
                        <Input type={"text"} name={'amount'}/>
                        <span> 단가 </span>
                        <Input type={"text"} name={'price'}/>
                    </ResponsiveWrap>
                </FormWrap>
            </FormMultiWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 10px;

  & span {
    font-weight: bold;
    margin-top: 10px;
  }
`
