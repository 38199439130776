import {useFindInvoice, useUpdateInvoiceStatus} from "../../../api/invoice";
import {useSetRecoilState} from "recoil";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useQueryClient} from "react-query";
import {useCallback, useEffect, useState} from "react";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import Button from "../../atoms/button";
import styled from "styled-components";
import invoiceJourneyman from '../../../assets/images/invoiceJourneyman.png'

export const InvoiceCreateTemplate = ({invoiceSeq}: { invoiceSeq: string }) => {
    const {data} = useFindInvoice({seq: invoiceSeq})
    const setAlert = useSetRecoilState(alertState)
    const queryClient = useQueryClient();
    const {mutate, isLoading} = useUpdateInvoiceStatus();
    const [invoiceFileUrl, setInvoiceFileUrl] = useState(null)
    const [info, setInfo] = useState({
        seq: invoiceSeq,
        bizEngName: '',
        bizKrName: '',
        bizEngAddress: '',
        bizEngCeo: '',
        bizKrCeo: '',
        bizNumber: '',
        bizTelephone: '',
        filterItems: [],
        items: [{name: '', amount: '', price: ''}],
        chinaAccountNumber: '',
        chinaAccountBank: '',
        invoiceFile: '',
        junmoonFile: '',
        chinaAccountOwner: '',
        chinaAccountFile: '',
        jeungbingFile: '',
        insertDate: '',
        status: 0,
        image: '',
        showYn: 0,
    })

    const onCreate = useCallback(async (seq: string) => {
        showAlert(setAlert, {
            title: '인보이스를 발행하시겠습니까?',
            onSubmit: async () => {
                await onPdfExport();
            },
        });
    }, [info]);

    const onPdfExport = useCallback(async () => {
        const el = document.querySelector('#invoice') as HTMLElement;
        el.style.overflow = 'unset';

        const canvas = await html2canvas(el);
        await canvas.toBlob((blob) => {
            const formData = new FormData();
            formData.append('data', JSON.stringify(info));
            if (blob) formData.append('invoiceFile', blob, 'image.png');

            el.style.overflow = 'scroll';

            mutate(formData, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['invoice']);
                    closeAlert(setAlert);
                }
            });
        }, 'image/png');
    }, [info]);

    const onPdfDownload = useCallback(async () => {
        const el = document.querySelector('#invoice') as HTMLElement;
        el.style.overflow = 'unset';

        const canvas = await html2canvas(el);
        const imageFile = canvas.toDataURL('image/png');


        const doc = new jspdf('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const widthRatio = pageWidth / canvas.width;
        const customHeight = canvas.height * widthRatio;

        doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight);

        let heightLeft = customHeight;
        let heightAdd = -pageHeight;


        while (heightLeft >= pageHeight) {
            doc.addPage();
            doc.addImage(imageFile, 'png', 0, heightAdd, pageWidth, customHeight);
            heightLeft -= pageHeight;
            heightAdd -= pageHeight;
        }

        doc.save('filename' + new Date().getTime() + '.pdf');
        el.style.overflow = 'scroll';
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: data?.seq,
                bizEngName: data?.bizEngName,
                bizKrName: data?.bizKrName,
                bizEngAddress: data?.bizEngAddress,
                bizEngCeo: data?.bizEngCeo,
                bizKrCeo: data?.bizKrCeo,
                bizNumber: data?.bizNumber,
                bizTelephone: data?.bizTelephone,
                filterItems: [],
                items: data?.items,
                invoiceFile: data?.invoiceFile,
                junmoonFile: data?.junmoonFile,
                chinaAccountNumber: data?.chinaAccountNumber,
                chinaAccountBank: data?.chinaAccountBank,
                chinaAccountOwner: data?.chinaAccountOwner,
                chinaAccountFile: data?.chinaAccountFile,
                jeungbingFile: data?.jeungbingFile,
                insertDate: data?.insertDate,
                status: data?.status,
                image: '',
                showYn: data?.showYn,
            });
        }
    }, [data]);
console.log(info)
    return (
        <>
            <InvoiceButton>
                <Button onClick={onPdfDownload} width={'100px'}>다운로드</Button>
                {
                    info.status === 0 && <Button onClick={() => onCreate(info.seq)} $isLoading={isLoading} width={'100px'}>발행하기</Button>
                }

            </InvoiceButton>
            <InvoiceTable id={'invoice'}>
                <InvoiceTest>
                    <InvoiceHeader>INVOICE</InvoiceHeader>
                    <InvoiceMiddleWrap>
                        <MiddleBox>
                            <InvoiceTopContentWrap>
                                <InvoiceTopContentLeft>
                                    <InvoiceTopHeader>
                                        P R O V I D E R
                                    </InvoiceTopHeader>
                                    <InvoiceLeftTopContentHeader>
                                        LINYI MANNUOJINGKAI INTERNATIONAL TRADING CO.,LTD
                                    </InvoiceLeftTopContentHeader>
                                    <InvoiceTopMiddleLeftContent>
                                        <InvoiceTopMiddleLeft>ADDRESS</InvoiceTopMiddleLeft>
                                        <InvoiceTopMiddleRight>205-09168 northeast of the intersection of Yihe
                                            Second Road and Mengshan Second Road, Linyi
                                            Comprehensive Bonded Zone
                                        </InvoiceTopMiddleRight>
                                    </InvoiceTopMiddleLeftContent>
                                    <InvoiceHeaderText>From To</InvoiceHeaderText>
                                    <InvoiceTopMiddleUnder>
                                        {info && info.bizEngName}
                                    </InvoiceTopMiddleUnder>
                                    <InvoiceTopMiddleUnder>{info && info.bizEngAddress}</InvoiceTopMiddleUnder>
                                    <InvoiceTopMiddleUnder>
                                        {`TOTAL($) ${info.items ? info.items.reduce((acc, cur) => {
                                            // 각 항목의 amount와 price를 곱하여 더함
                                            const itemTotal = Number(cur.amount) * Number(cur.price);
                                            return acc + itemTotal;
                                        }, 0).toLocaleString() : ''}`}
                                    </InvoiceTopMiddleUnder>
                                </InvoiceTopContentLeft>
                                <InvoiceTopContentRight>
                                    <InvoiceTopHeader>
                                        BANK INFORMATION
                                    </InvoiceTopHeader>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader>NAME</InvoiceTopRightHeader>
                                        <InvoiceTopRightContent>LINSHANG BANK CO. LTD</InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader>ADDRESS</InvoiceTopRightHeader>
                                        <InvoiceTopRightContent>NO.37 BEIJING ROAD, LINYI CITY, CHINA</InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader>ACCOUNT NO.</InvoiceTopRightHeader>
                                        <InvoiceTopRightContent>818880114621000037</InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader>SWIFTCODE</InvoiceTopRightHeader>
                                        <InvoiceTopRightContent>LYCBCNBL</InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader></InvoiceTopRightHeader>
                                        <InvoiceTopRightContent></InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader></InvoiceTopRightHeader>
                                        <InvoiceTopRightContent></InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                    <InvoiceTopRight>
                                        <InvoiceTopRightHeader></InvoiceTopRightHeader>
                                        <InvoiceTopRightContent></InvoiceTopRightContent>
                                    </InvoiceTopRight>
                                </InvoiceTopContentRight>
                            </InvoiceTopContentWrap>

                        </MiddleBox>
                    </InvoiceMiddleWrap>
                    <InvoiceContentWrap>
                        <InvoiceContentItems>
                            <InvoiceContentItemHeaderWrap>
                                <InvoiceContentItemHeader>ITEM</InvoiceContentItemHeader>
                                <InvoiceContentItemHeader>AMOUNT</InvoiceContentItemHeader>
                                <InvoiceContentItemHeader>PRICE</InvoiceContentItemHeader>
                                <InvoiceContentItemHeader>DEBIT</InvoiceContentItemHeader>
                                <InvoiceContentItemHeader>DESCRIPTION</InvoiceContentItemHeader>
                            </InvoiceContentItemHeaderWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items[0] ? 1 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[0] ? info?.items[0].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[0] ? info && Number(info?.items[0].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[0] ? info.items && Number(info?.items[0].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[0] ? `$ ${(Number(info && info?.items[0].amount) * Number(info && info?.items[0].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[1] ? 2 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[1] ? info?.items[1].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[1] ? info && Number(info?.items[1].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[1] ? info.items && Number(info?.items[1].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[1] ? `$ ${(Number(info && info?.items[1].amount) * Number(info && info?.items[1].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[2] ? 3 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[2] ? info?.items[2].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[2] ? info && Number(info?.items[2].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[2] ? info.items && Number(info?.items[2].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[2] ? `$ ${(Number(info && info?.items[2].amount) * Number(info && info?.items[2].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[3] ? 4 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[3] ? info?.items[3].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[3] ? info && Number(info?.items[3].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[3] ? info.items && Number(info?.items[3].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[3] ? `$ ${(Number(info && info?.items[3].amount) * Number(info && info?.items[3].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[4] ? 5 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[4] ? info?.items[4].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[4] ? info && Number(info?.items[4].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[4] ? info.items && Number(info?.items[4].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[4] ? `$ ${(Number(info && info?.items[4].amount) * Number(info && info?.items[4].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[5] ? 6 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[5] ? info?.items[5].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[5] ? info && Number(info?.items[5].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[5] ? info.items && Number(info?.items[5].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[5] ? `$ ${(Number(info && info?.items[5].amount) * Number(info && info?.items[5].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[6] ? 7 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[6] ? info?.items[6].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[6] ? Number(info?.items[6].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[6] ? info.items && Number(info?.items[6].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[6] ? `$ ${(Number(info && info?.items[6].amount) * Number(info && info?.items[6].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                            <InvoiceContentItemsWrap>
                                <InvoiceLowItems>
                                    <InvoiceItemsBox>
                                        <InvoiceItemLeft>{info.items && info?.items[7] ? 8 : ''}</InvoiceItemLeft>
                                        <InvoiceItemRight>{info.items[7] ? info?.items[7].name : ''}</InvoiceItemRight>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[7] ? info && Number(info?.items[7].amount).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[7] ? info.items && Number(info?.items[7].price).toLocaleString() : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem>{info.items[7] ? `$ ${(Number(info && info?.items[7].amount) * Number(info && info?.items[7].price)).toLocaleString()}` : ''}</InvoiceItem>
                                    </InvoiceItemsBox>
                                    <InvoiceItemsBox>
                                        <InvoiceItem></InvoiceItem>
                                    </InvoiceItemsBox>
                                </InvoiceLowItems>
                            </InvoiceContentItemsWrap>
                        </InvoiceContentItems>
                    </InvoiceContentWrap>
                    <InvoiceContentFooterWrap>
                        <InvoiceContentFooter>
                            <InvoiceContentFooterLeft>REMARK</InvoiceContentFooterLeft>
                            <InvoiceContentFooterRight>
                                <InvoiceContentFooterRightTop>구매대행 및 수입을 위한 사전무역대금 송금</InvoiceContentFooterRightTop>
                                <InvoiceContentFooterRightBottom>선적항: CNWEL - 양육항
                                    KRINC</InvoiceContentFooterRightBottom>
                            </InvoiceContentFooterRight>
                        </InvoiceContentFooter>
                    </InvoiceContentFooterWrap>
                    <InvoiceInfomation>
                        <InvoiceInfoWrap>
                            <InvoiceInfo>
                                <InvoiceInfoTop>{`${info && info.bizEngCeo} / ${info && info.bizEngName}`}</InvoiceInfoTop>
                                <InvoiceInfoMiddleBox>
                                    <InvoiceInfoMiddleLeft>Business registration No.</InvoiceInfoMiddleLeft>
                                    <InvoiceInfoMiddleRight>{info && info.bizNumber}</InvoiceInfoMiddleRight>
                                </InvoiceInfoMiddleBox>
                                <InvoiceInfoMiddleTelBox>
                                    <InvoiceInfoMiddleTelLeft>TEL</InvoiceInfoMiddleTelLeft>
                                    <InvoiceInfoMiddleTelRight>{info && info.bizTelephone && info.bizTelephone.replace(/(\d{3})(\d{3,4})(\d{4})/, '+82-$1-$2-$3')}</InvoiceInfoMiddleTelRight>
                                </InvoiceInfoMiddleTelBox>
                                <InvoiceStampBox></InvoiceStampBox>
                            </InvoiceInfo>
                            <InvoiceInfo>
                                <InvoiceInfoTop>LINYI MANNUOJINGKAI INTERNATIONAL TRADING CO.,LTD</InvoiceInfoTop>
                                <InvoiceInfoMiddleBox>
                                    <InvoiceInfoMiddleLeft>Business registration No.</InvoiceInfoMiddleLeft>
                                    <InvoiceInfoMiddleRight>91371300MAC32LJ94H</InvoiceInfoMiddleRight>
                                </InvoiceInfoMiddleBox>
                                <InvoiceInfoMiddleTelBox>
                                    <InvoiceInfoMiddleTelLeft>TEL</InvoiceInfoMiddleTelLeft>
                                    <InvoiceInfoMiddleTelRight> +86-1337-1296241</InvoiceInfoMiddleTelRight>
                                </InvoiceInfoMiddleTelBox>
                                <InvoiceStampBox>
                                    <img src={invoiceJourneyman} alt='invoiceCreate'
                                         style={{width: '25%', marginTop: '10px'}}/>
                                </InvoiceStampBox>
                            </InvoiceInfo>
                        </InvoiceInfoWrap>
                    </InvoiceInfomation>
                    <InvoiceFooter>
                        <FooterLeft> ISSUE DATE </FooterLeft>
                        <FooterRight> {info && info.insertDate.split(" ")[0]}</FooterRight>
                    </InvoiceFooter>
                </InvoiceTest>
            </InvoiceTable>
        </>
    )
}


const InvoiceTable = styled.div`
  width: 100%;
  border: 1px solid #444;
  overflow: scroll;
`

const InvoiceTest = styled.div`
  width: 100%;
  border: 1px solid #444;
`


const InvoiceHeader = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  background-color: #eee;
  padding: 10px 10px;
  text-align: center;
`

const InvoiceMiddleWrap = styled.div`
  width: 100%;
  display: flex;
`

const MiddleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MiddleBox2 = styled.div`
  width: 50%;
`

const InvoiceContentWrap = styled.div`
  width: 100%;
`


const InvoiceInfomation = styled.div`
  width: 100%;
`

const InvoiceFooter = styled.div`
  width: 100%;
  border-top: 1px solid #444;
  display: flex;
`

const FooterLeft = styled.div`
  width: 20%;
  padding: 10px 0px;
  border-right: 1px solid #444;
  text-align: center;
`

const FooterRight = styled.div`
  width: 80%;
  padding: 10px 0px;
  text-align: center;
`

const InvoiceButton = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: end;
`
const InvoiceTopHeaderWrap = styled.div`
  width: 100%;
  //border: 1px solid #444;

`


const InvoiceTopHeader = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  font-weight: 600;
  background-color: #eee;
`
const InvoiceTopContentWrap = styled.div`
  width: 100%;
  display: flex;
`

const InvoiceTopContentLeft = styled.div`
  width: 100%;
  border-right: 1px solid #444;

`

const InvoiceTopContentRight = styled.div`
  width: 100%;
`

const InvoiceLeftTopContentHeader = styled.div`
  width: 100%;
  padding: 5px;
  text-align: center;
  padding: 10px 0px;
  border-bottom: 1px solid #444;
  font-weight: 300;
`

const InvoiceTopMiddleLeftContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #444;
`
const InvoiceTopMiddleLeft = styled.div`
  width: 30%;
  padding: 0px 5px;
  text-align: center;

`

const InvoiceTopMiddleRight = styled.div`
  width: 70%;
  border-left: 1px solid #444;
  text-align: center;
  padding: 10px;

`

const InvoiceHeaderText = styled.h2`
  width: 100%;
  border-bottom: 1px solid #444;
  text-align: center;
  padding: 15px 0;
  font-weight: 600;
  background-color: #eee;
`
const InvoiceTopMiddleUnder = styled.div`
  width: 100%;
  border-bottom: 1px solid #444;
  text-align: center;
  padding: 26.5px 10px;

`
const InvoiceTopRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #444;
`

const InvoiceTopRightHeader = styled.div`
  width: 30%;
  padding: 22px 0;
  text-align: center;
`

const InvoiceTopRightContent = styled.div`
  width: 70%;
  border-left: 1px solid #444;
  padding: 22px 0px;
  text-align: center;
`

const InvoiceContentItems = styled.div`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;

`
const InvoiceContentItemHeaderWrap = styled.div`
  width: 100%;
  display: flex;
`

const InvoiceContentItemHeader = styled.div`
  width: 22%;
  text-align: center;
  padding: 8px;
  background-color: pink;
  border-right: 1px solid #444;
  border-bottom: 1px solid #444;

  &:last-child {
    border-right: none;
  }
`

const InvoiceContentItemsWrap = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #444;

  &:last-child {
    border-bottom: none;
  }
`

const InvoiceLowItems = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
`

const InvoiceItemsBox = styled.div`
  width: 22%;
  display: flex;
`

const InvoiceItemLeft = styled.div`
  width: 20%;
  padding: 10px 0px;
  border-right: 1px solid #444;
`
const InvoiceItemRight = styled.div`
  width: 80%;
  padding: 10px 0px;
  border-right: 1px solid #444;
`

const InvoiceItem = styled.div`
  width: 100%;
  padding: 10px 0px;
  border-right: 1px solid #444;
`

const InvoiceContentFooterWrap = styled.div`
  width: 100%;
`

const InvoiceContentFooter = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid #444;
`

const InvoiceContentFooterLeft = styled.div`
  border-right: 1px solid #444;
  width: 30%;
  padding: 20px 0px;
  text-align: center;
`

const InvoiceContentFooterRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InvoiceContentFooterRightTop = styled.div`
  padding: 5px 0px;
  border-bottom: 1px solid #444;
  text-align: center;
  width: 100%;
`

const InvoiceContentFooterRightBottom = styled.div`
  padding: 5px 0px;
  text-align: center;
`

const InvoiceInfoWrap = styled.div`
  width: 100%;
  display: flex;
`

const InvoiceInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #444;
  text-align: center;
  border-top: 1px solid #444;

  &:last-child {
    border-right: none;
  }
`

const InvoiceInfoTop = styled.div`
  width: 100%;
  border-bottom: 1px solid #444;
  padding: 10px 0px;
  background-color: #eee;
`

const InvoiceInfoMiddleBox = styled.div`
  width: 100%;
  display: flex;
  background-color: #eee;
`

const InvoiceInfoMiddleLeft = styled.div`
  width: 50%;
  border-bottom: 1px solid #444;
  padding: 10px 0px;
  border-right: 1px solid #444;
`

const InvoiceInfoMiddleRight = styled.div`
  width: 50%;
  border-bottom: 1px solid #444;
  padding: 10px 0px;
`

const InvoiceInfoMiddleTelBox = styled.div`
  width: 100%;
  display: flex;
  background-color: #eee;
`

const InvoiceInfoMiddleTelLeft = styled.div`
  width: 20%;
  padding: 10px 0px;
  border-bottom: 1px solid #444;
  border-right: 1px solid #444;
`

const InvoiceInfoMiddleTelRight = styled.div`
  width: 80%;
  padding: 10px 0px;
  border-bottom: 1px solid #444;
`

const InvoiceStampBox = styled.div`
  padding: 10px 0;
`
