import {UserTemplate} from "../../components/templates/user";
import LayoutTemplate from "../../components/templates/layout/left";

export const User = () => {
    return (
        <LayoutTemplate>
            <UserTemplate />
        </LayoutTemplate>
    );
};
