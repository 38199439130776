import {useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {invoiceTypes} from "../../../types/invoice";
import {
    useDeleteInvoice, useFindInvoice,
    useFindInvoices,
    useUpdateInvoiceStatus, useUpdateInvoiceStatusFinish
} from "../../../api/invoice";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import Button, {buttonThemes} from "../../atoms/button";
import {shapes, sizes} from "../../../types";
import {FlexWrap} from "../../../styles/component";
import {modalState, showModal} from "../../../recoil/modal";
import {InvoiceCreateTemplate} from "./create";
import {alertLevels} from "../../../types/alert";
import Table2 from "../../atoms/table/index2";

type itemType = {
    name: string;
    amount: string;
    price: string;
}
export const InvoiceItemsModal = ({invoiceSeq}: { invoiceSeq: string }) => {
    const {data: invoice, isLoading} = useFindInvoice({seq: invoiceSeq});

    const columns = useMemo(() => [
        {header: '이름', accessor: 'name', width: '33%'},
        {header: '개수', accessor: 'amount', width: '33%'},
        {header: '가격', accessor: 'price', width: '34%'},
    ], []);

    const data = useMemo(() => invoice?.items && invoice?.items.length > 0 ? invoice?.items.map((x: itemType) => {
        return x;
    }) : [], [invoice]);

    return (
        <FlexWrap $width={100}>
            <Table columns={columns} data={data} isLoading={isLoading}/>
        </FlexWrap>
    )
}

export const InvoiceTemplate = () => {
    const {data: invoices, isLoading} = useFindInvoices();
    const navigate = useNavigate();
    const {mutate} = useDeleteInvoice();
    const {mutate: updateMutate} = useUpdateInvoiceStatus();
    const {mutate: statusData} = useUpdateInvoiceStatusFinish();
    const {page} = useGetQueryString();
    const queryClient = useQueryClient();
    const setAlert = useSetRecoilState(alertState)
    const setModal = useSetRecoilState(modalState);

    const onInvoiceItemsModal = useCallback((seq: string) => {
        showModal(setModal, {children: <InvoiceItemsModal invoiceSeq={seq}/>})
    }, [])

    const onInvoiceCreateModal = useCallback((seq: string) => {
        showModal(setModal, {children: <InvoiceCreateTemplate invoiceSeq={seq}/>})
    }, [])

    const goAdd = useCallback(() => {
        navigate('/invoice/add');
    }, []);

    const onDownload = useCallback(({url, name}: { url: string, name: string }) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = `심플차이나_${name}_파일`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }, []);

    const onupdateStatus = useCallback(({seq, status}: { seq: string; status: number }) => {
        showAlert(setAlert, {
            title: `${status === 1 ? '완료 처리 하시겠습니까?' : '취소 하시겠습니까?'}`, onSubmit: () => {
                statusData({seq,status}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['invoice']);
                        closeAlert(setAlert);
                    }
                })
            }
        })
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/invoice/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', level: alertLevels.warning, onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['invoice']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '7%'},
            {header: '상호명\n(영문)', accessor: 'bizEngName', width: '8%'},
            {header: '상호명\n(한글)', accessor: 'bziKrName', width: '8%'},
            {header: '사업자 주소', accessor: 'bizEngAddress', width: '10%'},
            {header: '대표자명\n(영문)', accessor: 'bizEngCeo', width: '12%'},
            {header: '대표자명\n(한글)', accessor: 'bizKrCeo', width: '12%'},
            {header: '사업자 번호', accessor: 'bizNumber', width: '10%'},
            {header: '연락처', accessor: 'bizTelephone', width: '10%'},
            {header: '신청 금액 ($)', accessor: 'totalPrice', width: '10%'},
            {header: '품목', accessor: 'itemsAction', width: '5%'},
            {header: '중국\n계좌번호', accessor: 'chinaAccountNumber', width: '10%'},
            {header: '중국\n은행명', accessor: 'chinaAccountBank', width: '7%'},
            {header: '중국계좌\n수취인명', accessor: 'chinaAccountOwner', width: '8%'},
            {header: '인보이스\n발행여부', accessor: 'status', width: '7%'},
            {header: '중국\n계좌\n파일', accessor: 'chinaAccountFileAction', width: '5%'},
            {header: '송장\n파일', accessor: 'invoiceFileAction', width: '5%'},
            {header: '전문\n서류', accessor: 'junmoonFileAction', width: '5%'},
            {header: '중국\n증빙\n서류', accessor: 'jeungbingFileAction', width: '5%'},
            {header: '생성일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '9%'},
        ], []);
    const data = useMemo(() => invoices?.data && invoices?.data.length > 0 ? invoices?.data.map((x: invoiceTypes) => {
        return {
            ...x,
            totalPrice:  `$ ${x.items ? x.items && x?.items.reduce((acc, cur) => (acc + (Number(cur.price) * cur.amount)), 0).toLocaleString() : ''}`,
            itemsAction:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.normal} size={sizes.xs} shape={shapes.circle}
                            onClick={() => x.seq && onInvoiceItemsModal(x.seq)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="lucide lucide-search">
                            <circle cx="11" cy="11" r="8"/>
                            <path d="m21 21-4.3-4.3"/>
                        </svg>
                    </Button>
                </FlexWrap>,
            chinaAccountFileAction:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.normal} size={sizes.xs}
                            onClick={() => onDownload({url: x.chinaAccountFile || '', name: '계좌사본'})}
                            $isAble={!!x.chinaAccountFile} shape={shapes.circle}>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
                             strokeLinejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10">
                            </polyline>
                            <line x1="12" x2="12" y1="15" y2="3"></line>
                        </svg>
                    </Button>
                </FlexWrap>,
            jeungbingFileAction:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.normal} size={sizes.xs}
                            onClick={() => onDownload({url: x.jeungbingFile || '', name: '증빙서류사본'})}
                            $isAble={!!x.jeungbingFile} shape={shapes.circle}>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
                             strokeLinejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10">
                            </polyline>
                            <line x1="12" x2="12" y1="15" y2="3"></line>
                        </svg>
                    </Button>
                </FlexWrap>,
            junmoonFileAction:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.normal} size={sizes.xs}
                            onClick={() => onDownload({url: x.junmoonFile || '', name: '전문서류사본'})}
                            $isAble={!!x.junmoonFile} shape={shapes.circle}>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
                             strokeLinejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10">
                            </polyline>
                            <line x1="12" x2="12" y1="15" y2="3"></line>
                        </svg>
                    </Button>
                </FlexWrap>,
            invoiceFileAction:
                <FlexWrap $center={true}>
                    <Button theme={buttonThemes.normal} size={sizes.xs}
                            onClick={() => onDownload({url: x.invoiceFile || '', name: '송장파일사본'})}
                            $isAble={!!x.invoiceFile} shape={shapes.circle}>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
                             strokeLinejoin="round" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10">
                            </polyline>
                            <line x1="12" x2="12" y1="15" y2="3"></line>
                        </svg>
                    </Button>
                </FlexWrap>,
            status: x.status === 0 ? (
                <>
                    <Tag theme={'orange'}>미발행</Tag>
                </>
            ) : x.status === 1 ? (
                <>
                    <Tag theme={'navy'}>발행</Tag><br/>
                    <Tag theme={'red'}
                         onClick={() => onupdateStatus({seq: x.seq || '', status: x.status || 1})}>완료처리</Tag>
                </>

            ) : (
                <>
                    <Tag theme={'red'}>완료</Tag><br/>
                    <Tag theme={'green'}
                         onClick={() => onupdateStatus({seq: x.seq || '', status: x.status || 2})}>취소</Tag>
                </>

            ),
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete, onInvoiceCreateModal}),
        };
    }) : '', [invoices]);

    return (
        <LayoutRightTemplate title={'송장 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goEdit(seq)} isLoading={isLoading}/>
            <Pagination count={invoices ? invoices?.count[0]?.count : ''} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
