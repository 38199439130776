import {BannerTemplate} from "../../components/templates/banner";
import LayoutTemplate from "../../components/templates/layout/left";

export const Banner = () => {
    return (
        <LayoutTemplate>
            <BannerTemplate />
        </LayoutTemplate>
    );
};
