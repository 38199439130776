import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindUser, useUpdateUser} from "../../../api/user";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import { FormMultiWrap, FormWrap} from "../../../styles/component";

export const EditUserTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindUser({seq});
    const {mutate, isLoading} = useUpdateUser();
    const [info, setInfo] = useState({
        seq,
        id: '',
        email: '',
        telephone: '',
        nameKr: '',
        nameEng: '',
        bizKrName: '',
        bizEngName: '',
        bizEngAddress: '',
        bizNumber: '',
        bizFile: '',
        passportFile: '',
        image: '',
        role: 'Admin',
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);



    const onSubmit = useCallback(() => {
        if (!info.email) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.bizFile) formData.append('bizFile', info.bizFile);
        if (info.passportFile) formData.append('passportFile', info.passportFile);


        mutate(formData, {
            onSuccess: async () => {
                navigate('/user');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: data?.seq,
                id: data?.id,
                email: data?.email,
                telephone: data?.telephone,
                nameKr: data?.nameKr,
                nameEng: data?.nameEng,
                bizKrName: data?.bizKrName,
                bizEngName: data?.bizEngName,
                bizEngAddress: data?.bizEngAddress,
                bizNumber: data?.bizNumber,
                bizFile: data?.bizFile,
                passportFile: data?.passportFile,
                role: data?.role,
                image: '',
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'유저 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormMultiWrap>
                <FormWrap>
                    <span> 아이디 </span>
                    <Input type={"text"} value={info.id} name={'id'} onChange={onChangeInfo}/>

                    <span> 이메일 </span>
                    <Input type={"text"} value={info.email} name={'email'} onChange={onChangeInfo}/>

                    <span> 핸드폰 </span>
                    <Input type={"number"} value={info.telephone} name={'telephone'} onChange={onChangeInfo}/>

                    <span> 이름 (한글) </span>
                    <Input type={"text"} value={info.nameKr} name={'nameKr'} onChange={onChangeInfo}/>

                    <span> 이름 (영문) </span>
                    <Input type={"text"} value={info.nameEng} name={'nameEng'} onChange={onChangeInfo}/>

                    <span> 사업자 명 (한글) </span>
                    <Input type={"text"} value={info.bizKrName} name={'bizKrName'} onChange={onChangeInfo}/>

                    <span> 사업자 명 (영문) </span>
                    <Input type={"text"} value={info.bizEngName} name={'bizEngName'} onChange={onChangeInfo}/>

                    <span> 사업자 주소 (영문) </span>
                    <Input type={"text"} value={info.bizEngAddress} name={'bizEngAddress'} onChange={onChangeInfo}/>

                    <span> 사업자 번호 </span>
                    <Input type={"text"} value={info.bizNumber} name={'bizNumber'} onChange={onChangeInfo}/>

                    <span> 권한 </span>
                    <Select items={roleItems} essential={true} value={info.role} name={'role'} onChange={onChangeInfo}/>
                </FormWrap>

                <FormWrap>
                    <span> 사업자 등록증 업로드 </span>
                    <InputPreviewFile id={'file1'} name={'bizFile'} accept={'image/*'} initUrl={info.bizFile} setter={setInfo}/>
                </FormWrap>

                <FormWrap>
                    <span> 여권 사본 업로드 </span>
                    <InputPreviewFile id={'file2'} name={'passportFile'} accept={'image/*'} initUrl={info.passportFile} setter={setInfo}/>
                </FormWrap>
            </FormMultiWrap>
        </LayoutRightTemplate>
    )
}

