import styled from "styled-components";

export const FlexWrap = styled.div<{ $columns?: boolean, $center?: boolean, $gap?: number, $width?: number, $height?: number }>`
  display: flex;
  flex-direction: ${({$columns}) => $columns ? 'column' : 'row'};
  align-items: ${({$center}) => $center && 'center'};
  justify-content: ${({$center}) => $center ? 'center' : 'space-between'};
  gap: ${({$gap}) => $gap && `${$gap}px`};
  width: ${({$width}) => $width && `${$width}%`};
  height: ${({$height}) => $height && `${$height}%`};

  & > span {
    font-weight: bold;
    margin-top: 10px;
  }
`

export const GridWrap = styled.div<{ $repeat: string, $center?: boolean, $gap?: number }>`
  display: grid;
  grid-template-columns: ${({$repeat}) => $repeat};
  align-items: ${({$center}) => $center && 'center'};
  justify-content: ${({$center}) => $center && 'center'};
  gap: ${({$gap}) => $gap && `${$gap}px`};
`

export const FormMultiWrap = styled.div`
  display: flex;
  gap: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  
  
`

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 10px;

  & > span {
    font-weight: bold;
    margin-top: 10px;
  }
`

export const Spinner1 = styled.div`
  margin: 20px auto;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  background-color: #fff !important;
  border-top: 2.5px solid rgba(0,0,0, 0.3);
  border-right: 2.5px solid rgba(0,0,0, 0.3);
  border-bottom: 2.5px solid rgba(0,0,0, 0.3);
  border-left: 2.5px solid #fff;
  transform: translateZ(0);
  animation: spinner1 1.1s infinite linear;

  @keyframes spinner1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner2 = styled.div`
  border-radius: 50%;
  width: 19px;
  height: 19px;
  position: relative;
  border-top: 2.5px solid transparent;
  border-right: 2.5px solid #fff;
  border-bottom: 2.5px solid #fff;
  border-left: 2.5px solid #fff;
  transform: translateZ(0);
  animation: spinner1 1.1s infinite linear;

  @keyframes spinner1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner3 = styled.div`
  color: #fff;
  margin: 0 auto;
  border-radius: 50%;
  top: -24px;
  width: 10px;
  height: 10px;
  position: relative;
  transform: translateZ(0);
  animation: spinner3 1.8s infinite ease-in-out;
  animation-delay: -0.16s;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -15px;
    animation: spinner3 1.8s infinite ease-in-out;
    animation-delay: -0.32s;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 15px;
    animation: spinner3 1.8s infinite ease-in-out;
  }

  @keyframes spinner3 {
    0%, 80%, 100% {
      box-shadow: 0 24px 0 -12px;
    }
    40% {
      box-shadow: 0 24px 0 0;
    }
  }
`
