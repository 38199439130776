import {useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {Link, useNavigate} from "react-router-dom";
import {bannerTypes} from "../../../types/banner";
import {useDeleteBanner, useFindBanners} from "../../../api/banner";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import {alertLevels} from "../../../types/alert";

export const BannerTemplate = () => {
    const {data: banners, isLoading} = useFindBanners();
    const navigate = useNavigate();
    const {mutate} = useDeleteBanner();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/banner/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/banner/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', level: alertLevels.warning, onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['banner']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '5%'},
            {header: '이미지', accessor: 'bannerFile', width: '70%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => banners?.data && banners?.data.length > 0 ? banners?.data.map((x: bannerTypes) => {
        return {
            ...x,
            bannerFile: <img src={x.bannerFile} alt={'banner'} width={'200'}></img>,
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [banners]);

    return (
        <LayoutRightTemplate title={'배너 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goEdit(seq)} isLoading={isLoading}/>
            <Pagination count={banners ? banners?.count || 1 : ''} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
