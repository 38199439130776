import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindPopup, useUpdatePopup} from "../../../api/popup";
import {roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import { FormMultiWrap, FormWrap} from "../../../styles/component";

export const EditPopupTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindPopup({seq});
    const {mutate, isLoading} = useUpdatePopup();
    const [info, setInfo] = useState({
        seq,
        popupFile: '',
        showYn: 1
    });
    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);



    const onSubmit = useCallback(() => {
        if (!info.popupFile) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.popupFile) formData.append('popupFile', info.popupFile);


        mutate(formData, {
            onSuccess: async () => {
                navigate('/popup');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: data?.seq,
                popupFile: data?.popupFile,
                showYn: data?.showYn
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'팝업 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormMultiWrap>
                <FormWrap>
                    <span> 이미지 업로드 </span>
                    <InputPreviewFile id={'file1'} name={'popupFile'} accept={'image/*'} initUrl={info.popupFile} setter={setInfo}/>

                    <span> 공개 </span>
                    <Select items={showYnItems} essential={true} value={info.showYn} name={'showYn'} onChange={onChangeInfo}/>
                </FormWrap>
            </FormMultiWrap>
        </LayoutRightTemplate>
    )
}

