import {AddInvoiceTemplate} from "../../components/templates/invoice/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddInvoice = () => {
    return (
        <LayoutTemplate>
            <AddInvoiceTemplate />
        </LayoutTemplate>
    )
}
