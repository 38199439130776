import styled from "styled-components";
import {Dispatch, FormEvent, ReactNode, SetStateAction, useEffect, useState} from "react";

type props = {
    id?: string;
    name?: string;
    initUrl?: string;
    trigger?: ReactNode;
    accept?: string;
    setter: Dispatch<SetStateAction<any>>;
}

const InputPreviewFile = ({id, name, initUrl, setter, accept}: props) => {
    const [previewImg, setPreviewImg] = useState('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const beforeOnInput = (e: FormEvent<HTMLInputElement>) => {
        const file = (e?.target as HTMLInputElement).files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = function (data) {
                setPreviewImg('');
                setPreviewImg(data?.target?.result as string);
                const image = document.querySelector(`#${id}Preview`) as HTMLImageElement;
                if (image) image.src = data?.target?.result as string;
            }

            reader.readAsDataURL(file);
            setter((prev: []) => ({
                ...prev,
                [name as string]: file,
            }));
        }
    }

    useEffect(() => {
        if (initUrl) setPreviewImg(initUrl);
    }, [initUrl]);

    return (
        <FileInputWrap>
            <FileWrap htmlFor={id}>
                <PreviewImgWrap id={`${id}Preview`} src={initUrl} alt={'preview'} $isShow={!!previewImg}/>

                {
                    !initUrl &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-upload-cloud">
                        <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242"/>
                        <path d="M12 12v9"/>
                        <path d="m16 16-4-4-4 4"/>
                    </svg>
                }
            </FileWrap>

            <InputWrap type={'file'} id={id} name={name} onInput={(e) => beforeOnInput(e)} accept={accept}/>
        </FileInputWrap>
    )
}

const FileInputWrap = styled.div`
  display: flex;
  width: 100%;
`

const InputWrap = styled.input`
  display: none;
`

const FileWrap = styled.label`
  width: 100%;
  color: #DFDFDF;
  border: 1px solid #eee;
  border-radius: 6px;
  display: flex;
  padding: 10px 0;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PreviewImgWrap = styled.img<{ $isShow: boolean }>`
  width: 100%;
  display: ${props => props.$isShow ? 'block' : 'none'};
`;

export default InputPreviewFile;
