import {EditInvoiceTemplate} from "../../components/templates/invoice/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditInvoice = () => {
    return (
        <LayoutTemplate>
            <EditInvoiceTemplate />
        </LayoutTemplate>
    );
};
