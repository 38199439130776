import styled from "styled-components";
import {colors} from "../../../styles/colors";

type props = {
    items: itemProps[];
    name?: string;
    value?: string | number;
    essential?: boolean;
    onChange?(): void;
}

type itemProps = {
    value: string | number,
    text: string,
}

const Select = ({items, essential, value, name, onChange}: props) => {
    return (
        <SelectWrap onChange={onChange} value={value} name={name}>
            {
                !essential && <option value={''}> 미선택 </option>
            }


            {
                items && items.map((v, index) => {
                    return <option key={index} value={v.value}> {v.text} </option>
                })
            }
        </SelectWrap>
    )
}

const SelectWrap = styled.select`
  height: 45px;
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  cursor: pointer;
  appearance: none;

  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' class='lucide lucide-chevron-down'%3e%3cpath d='m6 9 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  outline: 0;
  
  transition: all 0.3s;
  
  &:focus {
    outline: 1px solid ${colors.main};
  }
`

export default Select;
