import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {bannerTypes} from "../types/banner";

export const useUpdateBanner = () => {
    const api = useCallback((data: FormData) => {
        return axios.put('/admin/api/v1/banner', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertBanner = () => {
    const api = useCallback((data: FormData) => {
        return axios.post('/admin/api/v1/banner', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteBanner = () => {
    const api = useCallback(({seq}: Pick<bannerTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/banner/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<bannerTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindBanner = ({seq}: bannerTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/banner/${seq}`);
    }, []);

    return useQuery(
        ['bannerDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindBanners = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/banner/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['banner', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
