import {useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {Link, useNavigate} from "react-router-dom";
import {userTypes} from "../../../types/user";
import {useDeleteUser, useFindUsers} from "../../../api/user";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import Button, {buttonThemes} from "../../atoms/button";
import {FlexWrap} from "../../../styles/component";
import {shapes, sizes} from "../../../types";
import {alertLevels} from "../../../types/alert";

export const UserTemplate = () => {
    const {data: users, isLoading} = useFindUsers();
    const navigate = useNavigate();
    const {mutate} = useDeleteUser();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/user/add');
    }, []);

    const onDownload = useCallback(({url, name}: { url: string, name: string }) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = `심플차이나_${name}_파일`;
        document.body.appendChild(a);
        a.rel = 'noreferrer'
        a.click();
        a.remove();
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/user/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', level: alertLevels.warning, onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['user']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '5%'},
            {header: '아이디', accessor: 'id', width: '9%'},
            {header: '이메일', accessor: 'email', width: '15%'},
            {header: '연락처', accessor: 'telephone', width: '7%'},
            {header: '이름 (한글)', accessor: 'nameKr', width: '12%'},
            {header: '이름 (영문)', accessor: 'nameEng', width: '12%'},
            {header: '사업자 명\n(한글)', accessor: 'bizKrName', width: '10%'},
            {header: '사업자 명\n(영문)', accessor: 'bizEngName', width: '10%'},
            {header: '사업자 주소\n(영문)', accessor: 'bizEngAddress', width: '10%'},
            {header: '사업자\n번호', accessor: 'bizNumber', width: '10%'},
            {header: '사업자\n등록증', accessor: 'bizFile', width: '5%'},
            {header: '여권\n사본', accessor: 'passportFile', width: '5%'},
            {header: '권한', accessor: 'role', width: '5%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => users?.data && users?.data.length > 0 ? users?.data.map((x: userTypes) => {
        return {
            ...x,
            telephone: x.telephone ? x.telephone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : '',
            bizFile: <FlexWrap $center={true}>
                <Button theme={buttonThemes.normal} size={sizes.xs} onClick={() => onDownload({url: x.bizFile || '', name: '계좌사본'})}
                        $isAble={x.bizFile ? true : false} shape={shapes.circle}>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round"
                         height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10">
                        </polyline>
                        <line x1="12" x2="12" y1="15" y2="3"></line>
                    </svg>
                </Button></FlexWrap>,
            passportFile: <FlexWrap $center={true}>
                <Button theme={buttonThemes.normal} size={sizes.xs} onClick={() => onDownload({url: x.passportFile || '', name: '여권사본'})}
                        $isAble={x.passportFile ? true : false} shape={shapes.circle}>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round"
                         height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10">
                        </polyline>
                        <line x1="12" x2="12" y1="15" y2="3"></line>
                    </svg>
                </Button></FlexWrap>,
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [users]);

    return (
        <LayoutRightTemplate title={'유저 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goEdit(seq)} isLoading={isLoading}/>
            <Pagination count={users ? users?.count[0]?.count || 1 : ''} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
