import {useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {useFindPrivacys, useUpdatePrivacy} from "../../../api/privacy";
import {Editor} from "../../atoms/editor";
import {FlexWrap} from "../../../styles/component";
import Button from "../../atoms/button";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";

export const PrivacyTemplate = () => {
    const {data: privacys} = useFindPrivacys();
    const {mutate, isLoading} = useUpdatePrivacy();
    const setAlert = useSetRecoilState(alertState);
    const [info1, setInfo1] = useState({
        seq: privacys?.data[0]?.seq,
        type: 0,
        content: '',
    });

    const [info2, setInfo2] = useState({
        seq: privacys?.data[1]?.seq,
        type: 0,
        content: '',
    });

    const onSubmit = useCallback(() => {
        showAlert(setAlert, {title: '수정 하시겠습니까?', onSubmit: () =>
                mutate(info1, {
                    onSuccess: () => {
                        closeAlert(setAlert);
                        window.location.reload();
                    }
                }),
        })
    }, [info1]);

    useEffect(() => {
        if (privacys?.data[0]) {
                setInfo1({
                    seq: privacys?.data[0]?.seq,
                    type: privacys?.data[0]?.type,
                    content:privacys?.data[0]?.content,
                });
        }
    }, [privacys?.data[0]]);

    const onChangeSubmit = useCallback(() => {
        showAlert(setAlert, {title: '수정 하시겠습니까?', onSubmit: () =>
                mutate(info2, {
                    onSuccess: () => {
                        closeAlert(setAlert);
                        window.location.reload();
                    }
                }),
        })
    }, [info2]);

    useEffect(() => {
        if (privacys?.data[1]) {
            setInfo2({
                seq: privacys?.data[1]?.seq,
                type: privacys?.data[1]?.type,
                content: privacys?.data[1]?.content,
            });
        }
    }, [privacys?.data[1]]);

    return (
        <LayoutRightTemplate title={'이용약관 관리'} clickTitle={'수정'}>
            <FlexWrap $gap={20} $height={100}>
                <FlexWrap $columns={true} $gap={10} $width={100} $height={100}>
                    <FlexWrap $width={100} $gap={390} $center={false}>
                        <span> 개인정보처리방침 </span>
                        <Button width={'100px'} onClick={onSubmit} $isLoading={isLoading}>변경</Button>
                    </FlexWrap>
                    <Editor value={info1.content} name={'content'} setter={setInfo1} isUploader={false}/>
                </FlexWrap>

                <FlexWrap $columns={true} $gap={10} $width={100} $height={100}>
                    <FlexWrap $width={100} $gap={390} $center={false}>
                        <span> 이용약관 </span>
                        <Button width={'100px'} onClick={onChangeSubmit} $isLoading={isLoading}>변경</Button>
                    </FlexWrap>
                    <Editor value={info2.content} name={'content'} setter={setInfo2} isUploader={false}/>
                </FlexWrap>
            </FlexWrap>
        </LayoutRightTemplate>
    );
};
