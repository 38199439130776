import {atom, SetterOrUpdater} from 'recoil';
import {layoutTypes} from "../types/layout";

export const layoutState = atom<layoutTypes>({
    key: 'layout',
    default: {
        fold: false,
    }
});

export const foldLayout = (hook: SetterOrUpdater<any>, {fold}: layoutTypes) => {
    hook({
        fold: !fold,
    });
};
