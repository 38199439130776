import Input from "../../atoms/input";
import styled from "styled-components";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertBoard} from "../../../api/board";
import {useNavigate} from "react-router-dom";
import {categoryItems, noticeItems, roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {Editor} from "../../atoms/editor";

export const AddBoardTemplate = () => {
    const {mutate, isLoading} = useInsertBoard();
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        categorySeq: 0,
        title: '',
        content: '',
        noticeYn: 0,
        showYn: 1,
        fileSeq: [''],
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.title || !info.content) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/board');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'게시판 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FlexWrap $gap={50} $height={100}>
                <FormWrap>
                    <span> 제목 </span>
                    <Input type={"text"} value={info.title} name={'title'} onChange={onChangeInfo}/>

                    <span> 카테고리 </span>
                    <Select items={categoryItems} essential={true} value={info.categorySeq} name={'categorySeq'} onChange={onChangeInfo}/>

                    <span> 공개 </span>
                    <Select items={showYnItems} essential={true} value={info.showYn} name={'showYn'} onChange={onChangeInfo}/>
                </FormWrap>

                <FlexWrap $columns={true} $gap={10} $width={100}>
                    <span> 내용 </span>
                    <Editor value={info.content} name={'content'} setter={setInfo}/>
                </FlexWrap>
            </FlexWrap>
        </LayoutRightTemplate>
    )
}
